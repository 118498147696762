html, body, #root {
	margin: 0;
	padding: 0;
	height: 100%;
	width: 100%;
	background-color: #000000;
	font-family: 'Roboto', sans-serif;
}

input:focus, textarea:focus, select:focus, button:focus {
	outline: none;
}

.page-container {
	height: 100%;
	min-width: 100%;
	position: fixed;
}

.top-align {
	z-index: 1000;
}

.animated-switch {
	position: absolute;
	overflow: auto;
	left: 0;
	top: 0;
	right: 0;
	height: 100%;
}

.animated-switch>div {
	position: absolute;
	overflow: auto;
	left: 0;
	top: 0;
	right: 0;
	height: 100%;
}

.black {
	background-color: #000000;
	height: 100%;
	width: 100%;
	position: absolute;
	top: 0;
	opacity: 0;
}

.lockup-text {
	font-size: 3.7rem;
}

.close-button-small-screen {
	position: absolute;
	top: 0;
	right: 15px;
	color: white;
	cursor: pointer;
	z-index: 10000;
}

.close-button-large-screen {
	position: absolute;
	top: 0;
	right: 15px;
	color: white;
	cursor: pointer;
	z-index: 10000;
}

.purple {
	overflow: hidden;
	height: 100%;
	width: 100%;
	-webkit-animation: fadein 3s;
	/* Safari, Chrome and Opera > 12.1 */
	-moz-animation: fadein 3s;
	/* Firefox < 16 */
	-ms-animation: fadein 3s;
	/* Internet Explorer */
	-o-animation: fadein 3s;
	/* Opera < 12.1 */
	animation: fadein 3s;
}

.grid-image {
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	height: 100%;
	width: 100%;
	padding: 0;
	-webkit-backface-visibility: hidden;
	-moz-backface-visibility: hidden;
	-ms-backface-visibility: hidden;
	backface-visibility: hidden;
	-webkit-transform: translateY(2000px) translateX(-2000px);
	transform: translateY(2000px) translateX(-2000px);
	text-align: center;
}

.enlarged-grid-image {
	min-height: 100%;
	min-width: 100vw;
	z-index: 9999;
}

.brand-grid {
	height: 100%;
	z-index: 9999;
	position: fixed;
	top: 0;
	left: 0;
}

@keyframes fadein {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

/* Firefox < 16 */

@-moz-keyframes fadein {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

/* Safari, Chrome and Opera > 12.1 */

@-webkit-keyframes fadein {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

/* Internet Explorer */

@-ms-keyframes fadein {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

/* Opera < 12.1 */

@-o-keyframes fadein {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

.above-brands {
	position: absolute;
	top: 0;
	z-index: 10002;
	min-height: 100%;
	min-width: 100%;
	overflow: hidden;
}

.fade-in {
	-webkit-animation: fadein 0.5s ease-in both;
	/* Safari, Chrome and Opera > 12.1 */
	-moz-animation: fadein 0.5s ease-in both;
	/* Firefox < 16 */
	-ms-animation: fadein 0.5s ease-in both;
	/* Internet Explorer */
	-o-animation: fadein 0.5s ease-in both;
	/* Opera < 12.1 */
	animation: fadein 0.5s ease-in both;
	min-height: 100%;
	top: 0;
	position: absolute;
}

.fade-out-delay {
	-webkit-animation: fade-out 0.5s ease-out both;
	animation: fade-out 0.5s ease-out both;
	animation-delay: 0.5s;
	opacity: 0;
}

.fade-out {
	-webkit-animation: fade-out 0.5s ease-out both;
	animation: fade-out 0.5s ease-out both;
	opacity: 0;
}

@-webkit-keyframes fade-out {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

@keyframes fade-out {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

.slide-out-tr {
	-webkit-animation: slide-out-tr 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
	animation: slide-out-tr 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
}

.slide-out-br {
	-webkit-animation: slide-out-br 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
	animation: slide-out-br 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
}

.slide-out-bl {
	-webkit-animation: slide-out-bl 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
	animation: slide-out-bl 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
}

.slide-out-tl {
	-webkit-animation: slide-out-tl 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
	animation: slide-out-tl 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
}

.slide-in-tl {
	-webkit-animation: slide-in-tl 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both !important;
	animation: slide-in-tl 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both !important;
}

.slide-in-tr {
	-webkit-animation: slide-in-tr 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both !important;
	animation: slide-in-tr 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both !important;
}

.slide-in-br {
	-webkit-animation: slide-in-br 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both !important;
	animation: slide-in-br 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both !important;
}

.slide-in-bl {
	-webkit-animation: slide-in-bl 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both !important;
	animation: slide-in-bl 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both !important;
}

@-webkit-keyframes slide-in-bl {
	0% {
		-webkit-transform: translateY(1000px) translateX(-1000px);
		transform: translateY(1000px) translateX(-1000px);
		opacity: 0;
	}
	100% {
		-webkit-transform: translateY(0) translateX(0);
		transform: translateY(0) translateX(0);
		opacity: 1;
	}
}

@keyframes slide-in-bl {
	0% {
		-webkit-transform: translateY(1000px) translateX(-1000px);
		transform: translateY(1000px) translateX(-1000px);
		opacity: 0;
	}
	100% {
		-webkit-transform: translateY(0) translateX(0);
		transform: translateY(0) translateX(0);
		opacity: 1;
	}
}

@-webkit-keyframes slide-in-tl {
	0% {
		-webkit-transform: translateY(-1000px) translateX(-1000px);
		transform: translateY(-1000px) translateX(-1000px);
		opacity: 0;
	}
	100% {
		-webkit-transform: translateY(0) translateX(0);
		transform: translateY(0) translateX(0);
		opacity: 1;
	}
}

@keyframes slide-in-tl {
	0% {
		-webkit-transform: translateY(-1000px) translateX(-1000px);
		transform: translateY(-1000px) translateX(-1000px);
		opacity: 0;
	}
	100% {
		-webkit-transform: translateY(0) translateX(0);
		transform: translateY(0) translateX(0);
		opacity: 1;
	}
}

@-webkit-keyframes slide-in-br {
	0% {
		-webkit-transform: translateY(1000px) translateX(1000px);
		transform: translateY(1000px) translateX(1000px);
		opacity: 0;
	}
	100% {
		-webkit-transform: translateY(0) translateX(0);
		transform: translateY(0) translateX(0);
		opacity: 1;
	}
}

@keyframes slide-in-br {
	0% {
		-webkit-transform: translateY(1000px) translateX(1000px);
		transform: translateY(1000px) translateX(1000px);
		opacity: 0;
	}
	100% {
		-webkit-transform: translateY(0) translateX(0);
		transform: translateY(0) translateX(0);
		opacity: 1;
	}
}

@-webkit-keyframes slide-in-tr {
	0% {
		-webkit-transform: translateY(-1000px) translateX(1000px);
		transform: translateY(-1000px) translateX(1000px);
		opacity: 0;
	}
	100% {
		-webkit-transform: translateY(0) translateX(0);
		transform: translateY(0) translateX(0);
		opacity: 1;
	}
}

@keyframes slide-in-tr {
	0% {
		-webkit-transform: translateY(-1000px) translateX(1000px);
		transform: translateY(-1000px) translateX(1000px);
		opacity: 0;
	}
	100% {
		-webkit-transform: translateY(0) translateX(0);
		transform: translateY(0) translateX(0);
		opacity: 1;
	}
}

@-webkit-keyframes slide-out-tr {
	0% {
		-webkit-transform: translateY(0) translateX(0);
		transform: translateY(0) translateX(0);
		opacity: 1;
	}
	100% {
		-webkit-transform: translateY(-1000px) translateX(1000px);
		transform: translateY(-1000px) translateX(1000px);
		opacity: 0;
	}
}

@keyframes slide-out-tr {
	0% {
		-webkit-transform: translateY(0) translateX(0);
		transform: translateY(0) translateX(0);
		opacity: 1;
	}
	100% {
		-webkit-transform: translateY(-1000px) translateX(1000px);
		transform: translateY(-1000px) translateX(1000px);
		opacity: 0;
	}
}

@-webkit-keyframes slide-out-br {
	0% {
		-webkit-transform: translateY(0) translateX(0);
		transform: translateY(0) translateX(0);
		opacity: 1;
	}
	100% {
		-webkit-transform: translateY(1000px) translateX(1000px);
		transform: translateY(1000px) translateX(1000px);
		opacity: 0;
	}
}

@keyframes slide-out-br {
	0% {
		-webkit-transform: translateY(0) translateX(0);
		transform: translateY(0) translateX(0);
		opacity: 1;
	}
	100% {
		-webkit-transform: translateY(1000px) translateX(1000px);
		transform: translateY(1000px) translateX(1000px);
		opacity: 0;
	}
}

@-webkit-keyframes slide-out-bl {
	0% {
		-webkit-transform: translateY(0) translateX(0);
		transform: translateY(0) translateX(0);
		opacity: 1;
	}
	100% {
		-webkit-transform: translateY(1000px) translateX(-1000px);
		transform: translateY(1000px) translateX(-1000px);
		opacity: 0;
	}
}

@keyframes slide-out-bl {
	0% {
		-webkit-transform: translateY(0) translateX(0);
		transform: translateY(0) translateX(0);
		opacity: 1;
	}
	100% {
		-webkit-transform: translateY(1000px) translateX(-1000px);
		transform: translateY(1000px) translateX(-1000px);
		opacity: 0;
	}
}

@-webkit-keyframes slide-out-tl {
	0% {
		-webkit-transform: translateY(0) translateX(0);
		transform: translateY(0) translateX(0);
		opacity: 1;
	}
	100% {
		-webkit-transform: translateY(-1000px) translateX(-1000px);
		transform: translateY(-1000px) translateX(-1000px);
		opacity: 0;
	}
}

@keyframes slide-out-tl {
	0% {
		-webkit-transform: translateY(0) translateX(0);
		transform: translateY(0) translateX(0);
		opacity: 1;
	}
	100% {
		-webkit-transform: translateY(-1000px) translateX(-1000px);
		transform: translateY(-1000px) translateX(-1000px);
		opacity: 0;
	}
}

.lockup {
	max-height: 50vh;
	width: 100%;
	pointer-events: none;
	height: auto;
}

.lockup-buttons {
	display: flex;
	align-items: center;
	justify-content: center;
}

.margin-right-20 {
	margin-right: 30%;
	margin-left: 1%;
}

.margin-left-20 {
	margin-left: 30%;
	margin-right: 1%;
}

.top-margin-offset {
	margin-top: -10vh;
}

@media screen and (min-width: 686px) {
	.right-float {
		float: right;
	}
	.left-float {
		float: left;
	}
	.cosyfish-button {
		display: inline-block;
		border: 2px solid white;
		min-width: 20rem;
		padding-left: 4rem;
		padding-right: 4rem;
		padding-top: 0.5rem;
		padding-bottom: 0.5rem;
		font-size: 2.4rem;
		color: white;
		background-color: transparent;
	}
}

@media screen and (max-width: 686px) {
	.right-float {
		margin-bottom: 20px;
	}
	.button-container {
		text-align: center;
	}
	.lockup-text {
		font-size: 2rem;
	}
	.cosyfish-button {
		display: inline-block;
		border: 2px solid white;
		min-width: 16rem;
		padding-left: 3rem;
		padding-right: 3rem;
		padding-top: 0.5rem;
		padding-bottom: 0.5rem;
		font-size: 2rem;
		color: white;
		background-color: transparent;
	}
}

@media screen and (min-width: 1200px) {
	.container {
		max-width: 1400px;
	}
}

.button-container {
	margin-top: 20px;
}

.slick-slider {
	height: 100%;
}

.top-align {
	max-width: 960px;
}

@media screen and (min-height: 830px) {
	.top-align {
		position: fixed;
		top: 30vh;
		left: 50vw;
		/* bring your own prefixes */
		transform: translate(-50%, -50%);
	}
	.lockup-text-container-hidden {
		margin-top: -12%;
		opacity: 0;
	}
	.lockup-text-container {
		margin-top: -12%;
		-webkit-animation: fadein 3s;
		/* Safari, Chrome and Opera > 12.1 */
		-moz-animation: fadein 3s;
		/* Firefox < 16 */
		-ms-animation: fadein 3s;
		/* Internet Explorer */
		-o-animation: fadein 3s;
		/* Opera < 12.1 */
		animation: fadein 3s;
	}
}

@media screen and (max-height: 830px) {
	.top-align {
		position: fixed;
		top: 40vh;
		left: 50vw;
		/* bring your own prefixes */
		transform: translate(-50%, -50%);
	}
	.lockup-text-container-hidden {
		margin-top: -5%;
		opacity: 0;
	}
	.lockup-text-container {
		margin-top: -5%;
		-webkit-animation: fadein 3s;
		/* Safari, Chrome and Opera > 12.1 */
		-moz-animation: fadein 3s;
		/* Firefox < 16 */
		-ms-animation: fadein 3s;
		/* Internet Explorer */
		-o-animation: fadein 3s;
		/* Opera < 12.1 */
		animation: fadein 3s;
	}
}

.clickable {
	cursor: pointer;
}

@media screen and (min-width: 992px) {
	.auto-height {
		height: 100%;
	}
	.half-width {
		width: 50%;
	}
	.close-button-small-screen {
		display: none;
	}
}

.half-height {
	height: 50%;
	margin: 0;
}

@media screen and (max-width: 992px) {
	.auto-height {
		height: 50%;
	}
	.half-width {
		width: 50%;
	}
	.close-button-large-screen {
		display: none;
	}
}

@media screen and (max-width: 397px) {
	.cosyfish-button {
		min-width: 100%;
		padding: 0px;
	}
}

.carousel-image {
	width: 100%;
	height: 100%;
	margin: 0 auto;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
}

.slider-div {
	height: 100%;
	overflow: hidden;
}

.grid-logo {
	height: 100%;
	max-height: 500px;
	position: relative;
	top: 50%;
	transform: translateY(-50%);
	-webkit-transform: translateY(-50%);
	width: auto;
	max-width: 100%;
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center;
}

.grid-text {
	margin-top: -10%;
	font-size: 3rem;
}

.fade-image {
	-webkit-animation: fadein 3s;
	/* Safari, Chrome and Opera > 12.1 */
	-moz-animation: fadein 3s;
	/* Firefox < 16 */
	-ms-animation: fadein 3s;
	/* Internet Explorer */
	-o-animation: fadein 3s;
	/* Opera < 12.1 */
	animation: fadein 3s;
}

.center {
	position: fixed;
	top: 50%;
	left: 50%;
	/* bring your own prefixes */
	transform: translate(-50%, -50%);
	z-index: 9000;
}

.logo-placeholder {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	height: auto;
	width: 100%;
}

.logo-placeholder>h1 {
	font-size: 4rem;
}

.logo-placeholder>h2 {
	font-size: 2.5rem;
}

.slick-slider * {
	pointer-events: none;
	height: 100%;
}

.slick-slide, .slick-slider .slick-track, .slick-slider .slick-list .slick-slide .slick-cloned {
	-webkit-transform: translate3d(0, 0, 0);
	-moz-transform: translate3d(0, 0, 0);
	-ms-transform: translate3d(0, 0, 0);
	-o-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
	-webkit-transform: translateZ(0);
	-moz-transform: translateZ(0);
	-ms-transform: translateZ(0);
	-o-transform: translateZ(0);
	transform: translateZ(0);
	-webkit-perspective: 1000;
	-moz-perspective: 1000;
	-ms-perspective: 1000;
	perspective: 1000;
	-webkit-backface-visibility: hidden;
	-moz-backface-visibility: hidden;
	-ms-backface-visibility: hidden;
	backface-visibility: hidden;
}

*::-webkit-media-controls-panel {
	display: none!important;
	-webkit-appearance: none;
}

/* Old shadow dom for play button */

*::-webkit-media-controls-play-button {
	display: none!important;
	-webkit-appearance: none;
}

/* New shadow dom for play button */

/* This one works! */

*::-webkit-media-controls-start-playback-button {
	display: none!important;
	-webkit-appearance: none;
}

/* ----------------------------------------------
 * Generated by Animista on 2021-3-20 12:32:50
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation scale-up-bl
 * ----------------------------------------
 */

.scale-up-bl {
	-webkit-animation: scale-up-bl 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	animation: scale-up-bl 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

@-webkit-keyframes scale-up-bl {
	0% {
		-webkit-transform: scale(0.5);
		transform: scale(0.5);
		-webkit-transform-origin: 0% 100%;
		transform-origin: 0% 100%;
	}
	100% {
		-webkit-transform: scale(1);
		transform: scale(1);
		-webkit-transform-origin: 0% 100%;
		transform-origin: 0% 100%;
	}
}

@keyframes scale-up-bl {
	0% {
		-webkit-transform: scale(0.5);
		transform: scale(0.5);
		-webkit-transform-origin: 0% 100%;
		transform-origin: 0% 100%;
	}
	100% {
		-webkit-transform: scale(1);
		transform: scale(1);
		-webkit-transform-origin: 0% 100%;
		transform-origin: 0% 100%;
	}
}

.brand-background {
	position: absolute;
	height: 100%;
	width: 100%;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
}

.brand-flexi-footer {
	position: absolute;
	bottom: 0;
	width: 100%;
	background-color: black;
}

.brand-footer {
	position: absolute;
	height: 27.5%;
	min-width: 100%;
	bottom: 0;
	background-color: black;
	z-index: 10;
}

.brand-lockup {
	height: 100%;
	width: 100%;
	margin: 0;
	padding: 0;
}

.zombie-glasses-image {
	margin-top: 2%;
	margin-left: -1.4%;
	width: 34.8%;
	height: auto;
	z-index: 11;
	position: absolute;
}

.zombie-cap-image {
	bottom: 0;
	right: 0;
	margin-right: -33.7%;
	margin-bottom: -12.6%;
	width: 54.5%;
	position: absolute;
	z-index: 11;
}

.zombie-footer-image {
	position: absolute;
	z-index: 11;
	bottom: 0;
	width: 46%;
	margin-left: 34.9%;
	margin-bottom: 1.5%;
}

.zombie-crew-image {
	position: absolute;
	z-index: 10;
	bottom: 20.5%;
	width: 63%;
	margin-right: 2%;
	right: 0;
}

.zombie-logo-image {
	position: absolute;
	width: 29.8%;
	margin-top: -0.5%;
	margin-right: 4.7%;
	right: 0;
}

.zombie-text {
	margin-left: 35.7%;
	margin-top: 3.6%;
}

.zombie-text p {
	font-family: 'Londrina Solid', cursive;
	font-weight: 300;
	line-height: 120%;
	color: white;
	margin-bottom: 1.9%;
}

.zombie-text a {
	position: absolute;
	color: #cc4556;
	font-family: 'Londrina Solid', cursive;
	font-weight: 300;
	border-bottom: 2px solid #cc4556;
	line-height: 120%;
	z-index: 9999;
}

.bean-background {
	background-color: #010101;
}

.bean-footer {
	background-color: #ef442d;
}

.bean-designs {
	width: 59.5%;
	margin-left: 8.8%;
	margin-top: 1%;
}

.bean-products {
	width: 34%;
	right: -3%;
	margin-top: 0.99%;
	position: absolute;
	z-index: 12;
}

.bean-logo {
	position: absolute;
	width: 32%;
	left: 39%;
	top: -0.5%;
}

.bean-type {
	position: absolute;
	width: 45%;
	margin-top: 16.6%;
	margin-left: 22.5%;
}

.bean-character {
	position: absolute;
	width: 31.5%;
	margin-left: 8%;
	margin-top: 2.5%;
}

.bean-link {
	position: absolute;
	color: white;
	font-family: 'Londrina Solid', cursive;
	font-weight: 300;
	border-bottom: 2px solid white;
	line-height: 10%;
	padding-bottom: 0.8%;
	z-index: 9999;
	bottom: 32.3%;
	margin-left: 48.7%;
}

.bean-instagram {
	position: absolute;
	bottom: 29.5%;
	left: 19.6%;
	width: 2.2%;
}

.bean-link:hover {
	color: white;
	border-color: white;
}

@media screen and (min-width: 769px) {
	.brand-mobile {
		display: none;
	}
}

@media screen and (max-width: 768px) {
	.desktop-page {
		display: none;
	}
	.brand-mobile {
		min-height: 100%;
		width: 100%;
		text-align: center;
	}
	.brand-mobile-footer {
		position: absolute;
		bottom: 0;
		height: 25%;
		width: 100%;
		background-color: black;
		z-index: 19;
	}
	.zombie-logo-image-mobile {
		position: relative;
		width: 85%;
	}
	.zombie-text-mobile {
		position: absolute;
		text-align: left;
		margin: 0 auto;
		margin-left: 5%;
		padding-right: 10%;
		z-index: 1;
	}
	.zombie-text-mobile p {
		font-family: 'Londrina Solid', sans-serif;
		font-weight: 300;
		line-height: 120%;
		color: white;
	}
	.zombie-text-mobile a {
		color: #cc4556;
		font-family: 'Londrina Solid', sans-serif;
		font-weight: 300;
		border-bottom: 2px solid #cc4556;
		line-height: 120%;
		z-index: 9999;
	}
	.zombie-footer-image-mobile {
		width: 171%;
		margin-top: -4%;
	}
	.brand-mobile-bean {
		min-height: 100%;
		min-width: 100%;
	}
	.bean-link-mobile {
		position: absolute;
		color: white;
		font-family: 'Londrina Solid', sans-serif;
		font-weight: 300;
		font-size: 6vw;
		border-bottom: 2px solid white;
		line-height: 120%;
		z-index: 9999;
		left: 10.5%;
		top: 90%;
	}
	.bean-link-mobile:hover {
		color: white;
		border-color: white;
	}
	.bean-mobile-footer {
		position: relative;
		margin-top: 10px;
		width: 100%;
		background-color: #ef442d;
	}
	.bean-logo-mobile {
		width: 100%;
	}
	.bean-type-mobile {
		width: 100%;
	}
	.bean-products-mobile {
		width: 100%;
		padding-top: 10px;
	}
	.zombie-crew-image-mobile {
		position: absolute;
		z-index: 0;
		width: 210%;
		top: -50%;
		right: -90%;
	}
	.bean-instagram-mobile {
		position: absolute;
		width: 8%;
		top: 90.5%;
		left: 55.5%;
	}
}

@media screen and (min-width: 570px) {
	.bean-link-mobile {
		font-size: 35px;
	}
}

.snowuns-logo {
	z-index: 10;
	position: absolute;
	width: 41.5%;
	left: 30.1%;
	top: -8.3%;
}

.snowuns-characters {
	position: absolute;
	top: -18.4%;
	right: -17%;
	width: 154.3%;
}

.snowuns-footer {
	background-color: #f05a94;
}

.snowuns-background {
	background-color: #eb315e;
}

.snowuns-merch {
	position: absolute;
	right: -31.4%;
	bottom: -19%;
	width: 162.5%;
}

.snowuns-text1 {
	position: absolute;
	top: 24%;
	right: 31.2%;
	width: 35.8%;
}

.snowuns-link {
	color: white;
	font-family: 'Boogaloo', sans-serif;
	text-decoration: underline;
}

.snowuns-book-link {
	position: absolute;
	right: 59.7%;
	top: 54.3%;
}

.snowuns-link:hover {
	color: black;
}

.snowuns-store {
	position: absolute;
	right: 59.5%;
	top: 66.5%;
}

.snowuns-reviews {
	position: absolute;
	width: 18%;
	top: 59%;
	right: 49%;
}

.snowuns-book {
	position: absolute;
	z-index: 11;
	width: 31%;
	right: 6%;
	top: 33%;
}

.snowuns-mobile-logo {
	width: 91%;
	margin-top: -6%;
}

.snowuns-mobile-text {
	margin-top: -10.1%;
	margin-left: 1%;
	width: 93%;
	position: relative;
	z-index: 9999;
}

.snowuns-mobile-reviews {
	width: 62.5%;
	margin-top: 1.5%;
	margin-right: 30%;
	z-index: 10;
}

.snowuns-mobile-characters {
	width: 100%;
	margin-top: -38.5%;
	margin-bottom: -20%;
	position: relative;
}

.snowuns-mobile-footer {
	position: relative;
	width: 100%;
	background-color: #f05a94;
}

.snowuns-mobile-merch {
	margin-top: 0%;
	margin-left: -7%;
	width: 120%;
	margin-bottom: 6%;
}

.snowuns-book-link-mobile {
	position: relative;
	z-index: 9999;
	right: 17%;
	top: -11%;
}

.bio-background {
	background: rgb(93, 61, 152);
	background: linear-gradient(180deg, rgba(93, 61, 152, 1) 41%, rgba(123, 87, 164, 1) 95%);
}

.bio-footer {
	background: rgb(13, 0, 30);
	background: linear-gradient(117deg, rgba(13, 0, 30, 1) 1%, rgba(91, 60, 151, 1) 55%);
}

.white-text {
	color: white;
}

.horizontal-cosyfish {
	width: 46%;
	margin: 0 auto;
}

.horizontal-cosyfish-container {
	width: 100%;
	text-align: center;
}

.bio-main-1 {
	font-family: 'Lato', sans-serif;
	position: absolute;
	top: 18.8%;
	margin-left: 4.8%;
}

.bio-main-1-logo {
	width: 11.6%;
	top: 17.6%;
	left: 21.8%;
	position: absolute;
}

.bio-main-2 {
	font-family: 'Lato', sans-serif;
	position: absolute;
	top: 26%;
	margin-left: 4.6%;
	line-height: 125%;
}

.bio-main-3 {
	font-family: 'Lato', sans-serif;
	position: absolute;
	top: 44.5%;
	margin-left: 4.6%;
	line-height: 125%;
}

.bio-main-4 {
	font-family: 'Lato', sans-serif;
	position: absolute;
	top: 51.6%;
	margin-left: 4.6%;
	line-height: 125%;
}

.bio-main-4-logo {
	width: 11.6%;
	top: 49.9%;
	left: 3.5%;
	position: absolute;
}

.star-editions-logo {
	position: absolute;
	width: 6.7%;
	top: 49%;
	right: 16.55%;
}

.cosyfish-map {
	position: absolute;
	width: 16.5%;
	top: 68.5%;
	left: 2.8%;
	z-index: 1;
}

.cosyfish-map-snowuns {
	position: absolute;
	width: 16.5%;
	left: 19%;
	top: 63%;
	z-index: 1;
}

.cosyfish-map-zombie {
	position: absolute;
	width: 11.5%;
	left: 19%;
	top: 75.5%;
	z-index: 1;
}

.cosyfish-map-bean {
	position: absolute;
	width: 11.1%;
	left: 19.4%;
	top: 86%;
	z-index: 1;
}

.snowuns-map-desc {
	color: white;
	font-family: 'Boogaloo', sans-serif;
	position: absolute;
	left: 36.2%;
	top: 68.5%;
	z-index: 1;
}

.zombie-map-desc {
	color: white;
	font-family: 'Boogaloo', sans-serif;
	position: absolute;
	left: 31%;
	top: 78.6%;
	z-index: 1;
}

.bean-map-desc {
	color: white;
	font-family: 'Boogaloo', sans-serif;
	position: absolute;
	left: 31%;
	top: 88.6%;
	z-index: 1;
}

.little-cosyfish {
	position: absolute;
	right: 0.2%;
	top: 93.5%;
	width: 4.5%;
	z-index: 1;
}

.scale-in-center {
	height: 100%;
	-webkit-animation: scale-in-center 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	animation: scale-in-center 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.scale-out-center {
	height: 100%;
	-webkit-animation: scale-out-center 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
	animation: scale-out-center 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
}

@-webkit-keyframes scale-in-center {
	0% {
		-webkit-transform: scale(0);
		transform: scale(0);
		opacity: 1;
	}
	100% {
		-webkit-transform: scale(1);
		transform: scale(1);
		opacity: 1;
	}
}

@keyframes scale-in-center {
	0% {
		-webkit-transform: scale(0);
		transform: scale(0);
		opacity: 1;
	}
	100% {
		-webkit-transform: scale(1);
		transform: scale(1);
		opacity: 1;
	}
}

@-webkit-keyframes scale-out-center {
	0% {
		-webkit-transform: scale(1);
		transform: scale(1);
		opacity: 1;
	}
	100% {
		-webkit-transform: scale(0);
		transform: scale(0);
		opacity: 1;
	}
}

@keyframes scale-out-center {
	0% {
		-webkit-transform: scale(1);
		transform: scale(1);
		opacity: 1;
	}
	100% {
		-webkit-transform: scale(0);
		transform: scale(0);
		opacity: 1;
	}
}

.bio-mobile {
	position: absolute;
	z-index: 9999;
}

.horizontal-cosyfish-mobile {
	z-index: 9999;
	margin-top: 5%;
	width: 100%;
	height: auto;
}

.bio-main-1-logo-mobile {
	margin-left: -2%;
	margin-top: -1%;
	margin-right: -2%;
}

.bio-main-3-logo-mobile {
	margin-left: -3%;
	margin-top: -6%;
	margin-right: -2%;
	margin-bottom: -5%;
}

.bio-main-1-mobile {
	font-family: 'Lato', sans-serif;
	color: white;
	margin-top: -4%;
	margin-left: 5%;
	margin-right: 5%;
	line-height: 16%;
}

.bio-main-2-mobile {
	font-family: 'Lato', sans-serif;
	color: white;
	margin-top: 6%;
	margin-left: 5%;
	margin-right: 5%;
	line-height: 120%;
}

.bio-main-3-mobile {
	font-family: 'Lato', sans-serif;
	color: white;
	margin-top: 4%;
	margin-left: 5%;
	margin-right: 5%;
	line-height: 120%;
}

.cosyfish-map-mobile {
	width: 92%;
	margin-top: 8%;
}

.bio-footer-mobile {
	position: relative;
	margin-top: 10px;
	width: 100%;
	background: rgb(13, 0, 30);
	background: linear-gradient(103deg, rgba(13, 0, 30, 1) 34%, rgba(87, 58, 151, 1) 90%);
}

.cosyfish-footer-snowuns {
	width: 82%;
	margin-top: 8%;
	margin-left: 7%;
}

.bio-mobile-container {
	background-color: #724fa0;
}

.z-index-9999 {
	z-index: 9999;
}

.z-index-0 {
	z-index: 0;
}

.black-cosyfish-button {
	color: black;
	background-color: white;
	padding: 1px 10px 1px 10px;
	background: none;
	box-shadow: none;
	border-radius: 0px;
	border: 2px solid black;
	font-family: 'Lato', sans-serif;
}

.form-control {
	border-radius: 0 !important;
	font-family: 'Lato', sans-serif;
}

.form-control:focus {
	border-color: #5E3D98;
	box-shadow: 0 0 0 0.2rem #5E3D98;
}

.form-label {
	font-family: 'Lato', sans-serif;
}

.Lato {
	font-family: 'Lato', sans-serif;
}

.modal-dialog .modal-content {
	border-radius: 0 !important;
}

.contact-desktop {
	position: absolute;
	right: 6%;
	bottom: 1.5%;
	z-index: 10;
	color: white;
	padding: 1px 12px 3px 12px;
	background: none;
	box-shadow: none;
	border-radius: 0px;
	border: 2px solid white;
	font-family: 'Lato', sans-serif;
}

.contact-mobile {
	margin-top: -1%;
	color: white;
	padding: 1px 12px 3px 12px;
	background: none;
	box-shadow: none;
	border-radius: 0px;
	border: 2px solid white;
	font-family: 'Lato', sans-serif;
}

.grecaptcha-badge {
	visibility: hidden;
}